import React, { Component } from 'react';
import { Form , Button } from 'semantic-ui-react';

import { getStepForm } from './Shared/stepFormField';

class HansoftInfo extends Component {
    nextPage = (e) => {
        e.preventDefault();
        this.props.nextStep();
    }

    render() {
        const { values, texts } = this.props;

        return (
            <div>
                <Form className="hansoftStepBody">
                    <h3>{ texts.header }</h3>
                    <div>{ texts.description }</div>
                    <br/>
                    { 
                        getStepForm({
                            label: texts.body.helixPlanHost,
                            placeholder: this.props.placeHolders.helixPlanHost,
                            onChange: this.props.handleChange('helixPlanHost'),
                            defaultValue: values.helixPlanHost
                        }) 
                    }
                    { 
                        getStepForm({
                            label: texts.body.helixPlanPort,
                            placeholder: this.props.placeHolders.helixPlanPort,
                            onChange: this.props.handleChange('helixPlanPort'),
                            defaultValue: values.helixPlanPort
                        }) 
                    }
                    { 
                        getStepForm({
                            label: texts.body.database,
                            placeholder: this.props.placeHolders.database,
                            onChange: this.props.handleChange('database'),
                            defaultValue: values.database
                        }) 
                    }
                    { 
                        getStepForm({
                            label: texts.body.sdkUser,
                            placeholder: this.props.placeHolders.sdkUser,
                            onChange: this.props.handleChange('sdkUser'),
                            defaultValue: values.sdkUser
                        }) 
                    }
                    { 
                        getStepForm({
                            label: texts.body.sdkPassword,
                            placeholder: this.props.placeHolders.sdkPassword,
                            onChange: this.props.handleChange('sdkPassword'),
                            defaultValue: values.sdkPassword
                        }) 
                    }
                </Form>
                <Button primary onClick={ this.nextPage }>{ texts.nextPageButton }</Button>
            </div>
        );
    }
}

export default HansoftInfo;