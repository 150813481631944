import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

class Error extends Component {
    render() {
        const texts = this.props.appConnectionInfo.texts.done;

        return (
            <div>
                <Container text textAlign='center'>
                    <h1>{ texts.body }</h1>
                </Container>
            </div>
        );
    };
}

export default withRouter(Error);