import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import download from "downloadjs";

import { Container, List, Button, Grid, GridRow, Dimmer, Loader} from 'semantic-ui-react';

class Download extends Component {

    state = {
        bDownloadRequestWasSent : false
    }

    render() {

        if (this.props.appConnectionInfo.connectionState !== 'verified') {
            return <Redirect to='/error'/>;
        }

        const texts = this.props.appConnectionInfo.texts.download;

        return (
            <div>
                { this.getDownloadingLoader() }
                <Container text>
                    <h2>{ texts.header }</h2>
                    <List ordered size='huge'>
                        <List.Item>{ texts.body.stepOne }<br/><br/>
                        <Grid >
                            <GridRow>
                                <Grid.Column width={10}>
                                    <Button 
                                        primary 
                                        onClick={ this.downloadSys }
                                    >
                                        { texts.download }
                                    </Button>
                                </Grid.Column>
                            </GridRow>
                        </Grid>
                        <br/>
                        </List.Item>
                        <List.Item>{ texts.body.stepTwo }</List.Item>
                        <List.Item>{ texts.body.stepThree }</List.Item>
                        <List.Item>{ texts.body.stepFour }</List.Item>
                        <List.Item>{ texts.body.stepFive }</List.Item>
                    </List>
                </Container>
            </div>
        );
    };

    downloadSys = () => {

        this.setState({ bDownloadRequestWasSent : true} );

        const restPoint = this.props.appConnectionInfo.config.RestAPI + 'logic/download?connection_id='
            + this.props.appConnectionInfo.connectionId
            + '&use_default_config=' + this.props.appConnectionInfo.bSkipCreateConfig;

        fetch(restPoint)
            .then((res) => {
                const contentType = res.headers.get("content-type");
                if (!contentType) {
                    this.props.history.push('/error');
                    return;
                }

                if (contentType.indexOf("application/zip") !== -1) {
                    res.blob().then(data => {
                            download(data, 'HelixPlanSlackIntegration.zip');
                            this.props.history.push('/done');
                    });
                } else if (contentType.indexOf("application/json") !== -1){
                  return res.json().then(json => {
                    this.props.history.push('/error');
                  });
                } else {
                    this.props.history.push('/error');
                }
            })
            .catch((err) => console.log(err));
    };

    getDownloadingLoader() {
        if (!this.state.bDownloadRequestWasSent) {
            return null;
        }

        return (
            <Dimmer active inverted>
                <Loader inverted>Downloading package.</Loader>
            </Dimmer>
        );
    }
}

export default withRouter(Download);