import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Button, Dimmer, Loader } from 'semantic-ui-react';
const queryString = require('query-string');

class Welcome extends Component {

    constructor(props) {
        super(props);

        if (this.props.location.search !== '') {
            const res = queryString.parse(this.props.location.search);
            if ("connection_id" in res && res.connection_id !== '') {
                this.props.appStateHandler('connectionId', res.connection_id);
                this.connectionInfo.connectionId = res.connection_id;
            }
        }
    }

    connectionInfo = {
        connectionId : -1
    };

    render() {
        if (this.props.appConnectionInfo.connectionState === 'notAuthorized') {
            return <Redirect to='/error'/>;
        }

        const texts = this.props.appConnectionInfo.texts.welcome;

        return (
            <div>
                { this.getInitLoader() }
                <Container text>
                    <h2>{ texts.header }</h2>
                    <p>{ texts.body }</p>
                </Container>
                <br/><br/>
                <Button 
                    color='grey' 
                    onClick={() => { 
                        this.props.appConnectionInfo.bSkipCreateConfig = true;
                        this.props.history.push('/download'); 
                    }
                }>
                    { texts.goToDownloadButton }
                </Button>
                &nbsp;
                <Button 
                    primary 
                    onClick={ () => { this.props.history.push('/wizard') }
                }>
                    { texts.goToWizardButton }
                </Button>
            </div>
        );
    };

    componentDidMount() {
        if (this.props.appConnectionInfo.connectionState === 'verified' &&
                    this.props.appConnectionInfo.connectionId !== -1) {
            return;
        }

        if (this.connectionInfo.connectionId === -1) {
            this.props.appStateHandler('connectionState', 'notAuthorized');
            return;
        }

        fetch(this.props.appConnectionInfo.config.RestAPI + "logic/check?connection_id=" + this.connectionInfo.connectionId)
            .then(response => response.json())
            .then((res) => {
                if (res.ok) {
                    this.props.appStateHandler('connectionState', 'verified')
                } else {
                    this.props.appStateHandler('connectionState', 'notAuthorized');
                }

            })
            .catch(() => this.props.appStateHandler('connectionState', 'notAuthorized'));
    }

    getInitLoader() {
        if (this.props.appConnectionInfo.connectionState !== 'init') {
            return null;
        }

        return (
            <Dimmer active inverted>
                <Loader inverted>Awaiting result from server</Loader>
            </Dimmer>
        );
    }
}

export default withRouter(Welcome);

