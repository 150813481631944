import React, { Component } from 'react';
import { Form, List, Button, Dimmer, Loader } from 'semantic-ui-react';

class Summary extends Component {

    state = {
        bDone : false,
        result: null
    };

    render() {
        const { texts } = this.props;

        return (
            <div>
                <Form className="hansoftStepBody">
                    { this.getDoneLoader() }
                    <h3>{ texts.header }</h3>
                    <div>{ texts.description }</div>
                    <br/>
                    <Form.Field>
                        <List size='large'>
                            <List.Item>
                                <b>Helix Plan Info</b>
                                    <List animated verticalAlign='middle'>
                                        {this.getPropertyAsTextFromResult('helixPlanHost')}
                                        {this.getPropertyAsTextFromResult('helixPlanPort')}
                                        {this.getPropertyAsTextFromResult('database')}
                                        {this.getPropertyAsTextFromResult('sdkUser')}
                                        {this.getPropertyAsTextFromResult('sdkPassword')}
                                    </List>
                            </List.Item>
                        </List>
                    </Form.Field>
                </Form>
                <Button color='grey' onClick={ this.prevPage }>{ texts.previousPageButton }</Button>
                &nbsp;
                <Button primary onClick={ this.finish }>{ texts.finishButton }</Button>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            result : {
                "helixPlanHost" : this.getProperty("helixPlanHost")
                , "helixPlanPort" : this.getProperty("helixPlanPort")
                , "database": this.getProperty("database")
                , "sdkUser": this.getProperty("sdkUser")
                , "sdkPassword": this.getProperty("sdkPassword")
            }
        });
    }

    finish = (e) => {
        e.preventDefault();
        this.props.finish(this.state.result);
        this.setState({ bDone : true});
    };

    prevPage = (e) => {
        e.preventDefault();
        this.props.prevStep();
    };

    getProperty = (propertyName) => {
        return this.props.values[propertyName] !== "" ?
            this.props.values[propertyName] :
            this.props.placeHolders[propertyName]
        ;
    };

    getPropertyAsTextFromResult = (propertyName) => {
        if (this.state.result === null)
            return null;

        return (
            <List.Item>{ propertyName + ' : '}  <i>{this.state.result[propertyName] }</i></List.Item>
        );
    };

    getDoneLoader() {
        if (!this.state.bDone) {
            return null;
        }

        return (
            <Dimmer active inverted>
                <Loader inverted>Sending information to server</Loader>
            </Dimmer>
        );
    }
}

export default Summary;