import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom'
import { Segment, Step, Container } from 'semantic-ui-react'

import './Steps/hansoftStepBody.css'
import HansoftInfo from './Steps/hansoftInformation';
import Summary from './Steps/summary'


class Wizard extends Component {
    state = {
        helixPlanHost: '',
        helixPlanPort: '',
        database: '',
        sdkUser: '',
        sdkPassword: '',
        step: 1,
        maxStep: 1
    };

    placeHolders = {
        helixPlanHost: 'localhost',
        helixPlanPort: '50256',
        database: 'Company projects',
        sdkUser: 'WebSDK',
        sdkPassword: 'password',
    };

    render() {
        if (this.props.appConnectionInfo.connectionState !== 'verified') {
            return <Redirect to='/error'/>;
        }

        return (
            <div className="wizard">
                <Container text>
                    <Step.Group attached='top' widths={2} ordered>
                        { this.getStep( 1, 'Helix Plan Info') }
                        { this.getStep( 2, 'Summary') }
                    </Step.Group>
                
                    <Segment attached height="100px">
                        { this.getStepContent() }
                    </Segment>
                </Container>
            </div>
        );
    };

    getStep = (stepNumber, stepName) => {
        if (stepNumber === this.state.step) {
            return (
                <Step active onClick={(e) => this.changeStep(stepNumber)}>
                    { this.getStepContentItem(stepName) }
                </Step>
            );
        } else if (stepNumber <= this.state.maxStep) {
            return (
                <Step completed onClick={(e) => this.changeStep(stepNumber)}>
                    { this.getStepContentItem(stepName) }
                </Step>
            );
        } else {
            return (
                <Step disabled onClick={(e) => this.changeStep(stepNumber)}>
                    { this.getStepContentItem(stepName) }
                </Step>
            );
        }
    };

    changeStep = (stepNumber) => {
        this.setState({
            step: stepNumber
        });
    };

    getStepContentItem = (stepName) => {
        return ( 
            <Step.Content>
                <Step.Title>{stepName}</Step.Title>
            </Step.Content>
        );
    };

    getStepContent = () => {
        const step = this.state.step;

        if (step === 1) {
            return (<HansoftInfo
                nextStep={this.nextStep}
                handleChange = {this.handleChange}
                values={this.state}
                placeHolders = {this.placeHolders}
                texts = { this.props.appConnectionInfo.texts.wizard.stepHansoftInfo }
            />);
        } else if (step === 2) {
            return (<Summary
                finish={this.finish}
                prevStep={this.prevStep}
                handleChange = {this.handleChange}
                values={this.state}
                placeHolders = {this.placeHolders}
                texts = { this.props.appConnectionInfo.texts.wizard.stepSummary }
            />);
        }
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });

        if (step + 1 > this.state.maxStep) {
            this.setState({
                maxStep: step + 1
            });
        }
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    finish = (summary) => {
        this.props.appConnectionInfo.bSkipCreateConfig = false;
        fetch(this.props.appConnectionInfo.config.RestAPI + 'logic/store_config', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                connectionId : this.props.appConnectionInfo.connectionId,
                result : summary
            })
        })
        .then(response => response.json())
        .then((res) => {
            if (res.ok) {
                this.props.history.push('/download');
            } else {
                this.props.history.push('/error');
            }
        })
        .catch((e) => console.log(e));
        
    };

    handleChange = input => event => {
        this.setState({ [input] : event.target.value });
    };
}

export default withRouter(Wizard);