import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import AppFooter from './AppFooter/appFooter';
import AppHeader from './AppHeader/appHeader';
import Wizard from './Routes/Wizard/wizard';
import Welcome from './Routes/Welcome/welcome';
import Download from './Routes/Download/download';
import Error from './Routes/Error/error';
import Done from './Routes/Done/done';
import './app.css';
import config  from './Config/config';
import texts  from './Config/texts';

class App extends Component {

  state = {
    connectionId : -1,
    connectionState : 'init',
    config : config,
    texts: texts,
    bSkipCreateConfig : true
  };

  render() {
    return (
      <Router>
        <div className="appContainer">
          <AppHeader />
          <br/>
          <Redirect from="/" to="/welcome" />
          <div className='appBody'>
            <Route default
              path='/welcome' 
              render={() => (
                <Welcome 
                  appConnectionInfo={this.state} 
                  appStateHandler={this.stateHandler}
                />
              )} 
            />
            <Route path='/wizard' render={() => (<Wizard appConnectionInfo={this.state}/>) }/>
            <Route path='/download' render={() => (<Download appConnectionInfo={this.state}/>) }/>
            <Route path='/error' render={() => (<Error appConnectionInfo={this.state}/>) }/>
            <Route path='/done' render={() => (<Done appConnectionInfo={this.state}/>) }/>
          </div>
          <br/>
          <AppFooter />
        </div>
      </Router>
    );
  }

  stateHandler = (property, value) => {
    if (property in this.state)
      this.setState({ [property] : value});
  };
  
}

export default App;
