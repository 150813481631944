import React, { Component } from 'react';
import perfLogo from '../Assets/perforce_logo.svg'
import { Grid } from 'semantic-ui-react';
import './appFooter.css'


class Footer extends Component {
    render() {
        const currentDate = new Date();
        return (
            <div className="appFooter">
                <Grid.Row>
                    <div className='perforceLogo'>
                        <a href="https://www.perforce.com"><img src={perfLogo} alt='Perforce logo'/></a>
                    </div>
                    <div className="copyright">Copyright © { currentDate.getFullYear() } Perforce Software, Inc. All rights reserved. &nbsp;|&nbsp;&nbsp;
                        <a href="https://www.perforce.com/terms-use">Terms of Use</a> &nbsp;| &nbsp;
                        <a href="https://www.perforce.com/privacy-policy">Privacy Policy</a>
                    </div>
                </Grid.Row>
            </div>
        );
    };
}

export default Footer;