import React, { Component } from 'react';
import helixPlanLogo from '../Assets/helixplan_logo.svg'
import honeycomb from '../Assets/honeycomb_header.png';
import './appHeader.css'


class Header extends Component {
    render() {
       return (
            <div className="appHeader">
                <div className="honeycomb">
                    <img src={honeycomb} alt='Honeycomb header'/>
                </div>
                <div className="logoContainer">
                        <img src={helixPlanLogo} alt='Helix Plan logo' width={300} height={300}/>
                </div>
            </div>
        );
    };
}

export default Header;



