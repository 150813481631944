import React from 'react';
import { Form , Input } from 'semantic-ui-react';

export function getStepForm ({label, placeholder, onChange, defaultValue}) {
    return (<Form.Field>
        <label>{ label }</label>
        <Input
            placeholder={ placeholder }
            onChange={ onChange }
            defaultValue={ defaultValue }
        />
    </Form.Field>);
};